export enum ETopic {
  All = "0",
  UNIT1_N5 = "unit1_n5",
  UNIT2_N5 = "unit2_n5",
  UNIT3_N5 = "unit3_n5",
  UNIT4_N5 = "unit4_n5",
  UNIT5_N5 = "unit5_n5",
  UNIT6_N5 = "unit6_n5",
  UNIT7_N5 = "unit7_n5",
  UNIT8_N5 = "unit8_n5",
  UNIT9_N5 = "unit9_n5",
  UNIT10_N5 = "unit10_n5",
  UNIT11_N5 = "unit11_n5",
  UNIT12_N5 = "unit12_n5",
  UNIT13_N5 = "unit13_n5",
  UNIT14_N5 = "unit14_n5",
  UNIT15_N5 = "unit15_n5",
  UNIT16_N5 = "unit16_n5",
  UNIT17_N5 = "unit17_n5",
  UNIT18_N5 = "unit18_n5",
  UNIT19_N5 = "unit19_n5",
  UNIT20_N5 = "unit20_n5",
  UNIT21_N5 = "unit21_n5",
  UNIT22_N5 = "unit22_n5",
  UNIT23_N5 = "unit23_n5",
  UNIT24_N5 = "unit24_n5",
  UNIT25_N5 = "unit25_n5",
  UNIT26_N5 = "unit26_n5",
  UNIT27_N5 = "unit27_n5",
  UNIT28_N5 = "unit28_n5",
}
