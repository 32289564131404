export interface ICodeIconProps {
  className?: string;
}

export function CodeIcon({ className = "" }: ICodeIconProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} fill-white`}
    >
      <path d="M15.9032 5.13597C16.0438 4.99552 16.2345 4.91663 16.4332 4.91663C16.632 4.91663 16.8226 4.99552 16.9632 5.13597L23.4632 11.636C23.6037 11.7766 23.6825 11.9672 23.6825 12.166C23.6825 12.3647 23.6037 12.5553 23.4632 12.696L16.9632 19.196C16.8715 19.2947 16.7549 19.3669 16.6257 19.4049C16.4964 19.4429 16.3593 19.4453 16.2288 19.412C16.0983 19.3786 15.9792 19.3106 15.884 19.2152C15.7889 19.1199 15.7212 19.0006 15.6882 18.87C15.6548 18.7396 15.6571 18.6026 15.695 18.4735C15.7328 18.3443 15.8047 18.2277 15.9032 18.136L21.8732 12.166L15.9032 6.19597C15.7628 6.05534 15.6839 5.86472 15.6839 5.66597C15.6839 5.46722 15.7628 5.27659 15.9032 5.13597ZM9.46321 5.13597C9.60366 5.27659 9.68255 5.46722 9.68255 5.66597C9.68255 5.86472 9.60366 6.05534 9.46321 6.19597L3.49321 12.166L9.46321 18.136C9.56193 18.2276 9.6341 18.3442 9.67212 18.4735C9.71013 18.6027 9.71258 18.7398 9.67921 18.8704C9.64584 19.0009 9.57788 19.12 9.48249 19.2151C9.3871 19.3103 9.26782 19.3779 9.13721 19.411C9.00683 19.4444 8.86986 19.4421 8.7407 19.4042C8.61153 19.3664 8.49495 19.2944 8.40321 19.196L1.90321 12.696C1.76276 12.5553 1.68387 12.3647 1.68387 12.166C1.68387 11.9672 1.76276 11.7766 1.90321 11.636L8.40321 5.13597C8.54383 4.99552 8.73446 4.91663 8.93321 4.91663C9.13196 4.91663 9.32258 4.99552 9.46321 5.13597Z" />
    </svg>
  );
}
