import { IWordOrSentence } from "../redux/features/words/slices";
import { ETopic } from "../types/topic.types";

export const vocabularyUnit1: IWordOrSentence[] = [
  {
    hiragana: "わたし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "私",
    meaning: "Tôi",
  },
  {
    hiragana: "わたしたち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "私たち",
    meaning: "Chúng tôi",
  },
  {
    hiragana: "あなた",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Anh/chị, ông/bà, bạn (ngôi thứ 2 số ít)",
  },
  {
    hiragana: "あのひと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "あの人",
    meaning: "Người kia",
  },
  {
    hiragana: "あのかた",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "あの方",
    meaning: "Vị này Lịch sự tương đương với あのひと",
  },
  {
    hiragana: "みなさん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Các bạn, các anh, các chị, mọi người",
  },
  {
    hiragana: "～さん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning:
      "Anh ～, Chị ～, Ông ～, Bà ～ (cách gọi người khác 1 cách lịch sự)",
  },
  {
    hiragana: "～ちゃん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Bé ( dùng cho nữ) hoặc gọi thân mật cho trẻ con ( cả nam lẫn nữ)",
  },
  {
    hiragana: "～くん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Bé (dùng cho nam) hoặc gọi thân mật",
  },
  {
    hiragana: "～じん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "～人",
    meaning: "Người nước ～",
  },
  {
    hiragana: "せんせい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "先生",
    meaning: "Giáo viên",
  },
  {
    hiragana: "きょうし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "教師",
    meaning: "Giáo viên ( dùng để nói đến nghề nghiệp)",
  },
  {
    hiragana: "がくせい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "学生",
    meaning: "học sinh, sinh viên",
  },
  {
    hiragana: "かいしゃいん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "会社員",
    meaning: "nhân viên công ty",
  },
  {
    hiragana: "～しゃいん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "社員",
    meaning: "nhân viên công ty ～",
  },
  {
    hiragana: "ぎんこういん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "銀行員",
    meaning: "nhân viên ngân hàng",
  },
  {
    hiragana: "いしゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "医者",
    meaning: "bác sĩ",
  },
  {
    hiragana: "けんきゅうしゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "研究者",
    meaning: " nhà nghiên cứu",
  },
  {
    hiragana: "エンジニア",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "kỹ sư",
  },
  {
    hiragana: "だいがく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "大学",
    meaning: "trường đại học",
  },
  {
    hiragana: "びょういん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "病院",
    meaning: "bệnh viện",
  },
  {
    hiragana: "でんき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "電気",
    meaning: "Điện, đèn điện",
  },
  {
    hiragana: "だれ（どなた）",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "誰",
    meaning: "ai (ngài nào, vị nào)",
  },
  {
    hiragana: "―さい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "～歳",
    meaning: "tuổi",
  },
  {
    hiragana: "なんさい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "何歳",
    meaning: "mấy tuổi",
  },
  {
    hiragana: "はい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "vâng",
  },
  {
    hiragana: "いいえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "không",
  },
  {
    hiragana: "しつれいですが",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "失礼ですが",
    meaning: "Xin lỗi ( khi muốn nhờ ai việc gì đó)",
  },
  {
    hiragana: "おなまえは？",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "お名前は",
    meaning: "Bạn tên gì?",
  },
  {
    hiragana: "はじめまして。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "初めて",
    meaning: "chào lần đầu gặp nhau",
  },
  {
    hiragana: "どうぞよろしく[おねがいします]。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "どうぞよろしく「お願いします」。",
    meaning: "rất hân hạnh được làm quen",
  },
  {
    hiragana: "こちらは～さんです。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "đây là ngài",
  },
  {
    hiragana: "～からきました。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "～から来ました",
    meaning: "đến từ ～",
  },
  {
    hiragana: "アメリカ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Mỹ",
  },
  {
    hiragana: "イギリス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Anh",
  },
  {
    hiragana: "インド",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Ấn Độ",
  },
  {
    hiragana: "インドネシア",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Indonesia",
  },
  {
    hiragana: "かんこく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "韓国",
    meaning: "Hàn quốc",
  },
  {
    hiragana: "タイ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Thái Lan",
  },
  {
    hiragana: "ちゅうごく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "中国",
    meaning: "Trung Quốc",
  },
  {
    hiragana: "ドイツ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Đức",
  },
  {
    hiragana: "にほん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "日本",
    meaning: "Nhật",
  },
  {
    hiragana: "フランス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Pháp",
  },
  {
    hiragana: "ブラジル",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: " ",
    meaning: "Brazil",
  },
  {
    hiragana: "さくらだいがく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT1_N5,
    kanji: "さ‌く‌ら‌大‌学",
    meaning: "Trường ĐH Sakura (Hoa Anh Đào)",
  },
];

export const vocabularyUnit2: IWordOrSentence[] = [
  {
    hiragana: "これ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Cái này, đây (vật ở gần người nói)",
  },
  {
    hiragana: "それ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Cái đó, đó (vật ở gần người nghe)",
  },
  {
    hiragana: "あれ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Cái kia, kia (vật ở xa cả người nói và người nghe)",
  },
  {
    hiragana: "この~",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "~ này",
  },
  {
    hiragana: "その~",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "~ đó",
  },
  {
    hiragana: "あの~",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "~ kia",
  },
  {
    hiragana: "ほん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "本",
    meaning: "Sách",
  },
  {
    hiragana: "じしょ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "辞書",
    meaning: "Từ điển",
  },
  {
    hiragana: "ざっし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "雑誌",
    meaning: "Tạp chí",
  },
  {
    hiragana: "しんぶん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "新聞",
    meaning: "Báo",
  },
  {
    hiragana: "ノート",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Vở",
  },
  {
    hiragana: "てちょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "手帳",
    meaning: "Sổ tay",
  },
  {
    hiragana: "めいし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "名詞",
    meaning: "Danh thiếp",
  },
  {
    hiragana: "カード",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Thẻ, cạc",
  },
  {
    hiragana: "テレホンカード",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Thẻ điện thoại",
  },
  {
    hiragana: "えんぴつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "鉛筆",
    meaning: "Bút chì",
  },
  {
    hiragana: "ボールペン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Bút bi",
  },
  {
    hiragana: "シャープペンシル",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Bút chì kim, bút chì bấm",
  },
  {
    hiragana: "かぎ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Chìa khóa",
  },
  {
    hiragana: "とけい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "時計",
    meaning: "Đồng hồ",
  },
  {
    hiragana: "かさ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "傘",
    meaning: "ô, dù",
  },
  {
    hiragana: "かばん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Cặp sách, túi sách",
  },
  {
    hiragana: "[カセット] テーブ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Băng [cát-xét]",
  },
  {
    hiragana: "テープレコーダー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Máy ghi âm",
  },
  {
    hiragana: "テレビ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Tivi",
  },
  {
    hiragana: "ラジオ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Radio",
  },
  {
    hiragana: "カメラ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Máy ảnh",
  },
  {
    hiragana: "コンピュータ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Máy vi tính",
  },
  {
    hiragana: "じどうしゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "自動車",
    meaning: "Ô tô, xe hơi",
  },
  {
    hiragana: "つくえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "机",
    meaning: "Cái bàn",
  },
  {
    hiragana: "いす",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Ghế",
  },
  {
    hiragana: "チョコレート",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Chocolate",
  },
  {
    hiragana: "コーヒー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Cà phê",
  },
  {
    hiragana: "えいご",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "英語",
    meaning: "Tiếng Anh",
  },
  {
    hiragana: "にほんご",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "日本語",
    meaning: "Tiếng Nhật",
  },
  {
    hiragana: "∼ご",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "∼語",
    meaning: "Tiếng~",
  },
  {
    hiragana: "なん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "何",
    meaning: "Cái gì",
  },
  {
    hiragana: "そう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Đúng vậy",
  },
  {
    hiragana: "ちがいます。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "違います。",
    meaning: "Nhầm rồi",
  },
  {
    hiragana: "そうですか。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Thế à?",
  },
  {
    hiragana: "あのう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: " À…(được sử dụng để thể hiện sự do dự)",
  },
  {
    hiragana: "ほんのきもちです",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "ほんの気持ちです",
    meaning: "Đây là chút quà nhỏ của tôi",
  },
  {
    hiragana: "どうぞ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Xin mời",
  },
  {
    hiragana: "どうも",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Cảm ơn",
  },
  {
    hiragana: "[どうも] ありがとう[ございます]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Cảm ơn nhiều",
  },
  {
    hiragana: "これから おせわになります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: "これから お世話になります",
    meaning: "Từ này mong được anh/chị giúp đỡ",
  },
  {
    hiragana: "こちらこそ よろしく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT2_N5,
    kanji: " ",
    meaning: "Chính tôi mới mong được anh/chị giúp đỡ",
  },
];

export const vocabularyUnit3: IWordOrSentence[] = [
  {
    hiragana: "ここ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "chỗ này, đây",
  },
  {
    hiragana: "そこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "chỗ đó, đó",
  },
  {
    hiragana: "あそこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "chỗ kia, kia",
  },
  {
    hiragana: "どこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "chỗ nào, đâu",
  },
  {
    hiragana: "こちら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "phía này, đằng này, chỗ này, đây (là cách nói lịch sự của ここ)",
  },
  {
    hiragana: "そちら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "phía đó, đằng đó, chỗ đó, đó (là cách nói lịch sự của そこ)",
  },
  {
    hiragana: "あちら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "phía kia, đằng kia, chỗ kia, kia (cách nói lịch sự của あそこ)",
  },
  {
    hiragana: "どちら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "phía nào, đằng nào, chỗ nào, đâu (cách nói lịch sự của どこ )",
  },
  {
    hiragana: "きょうしつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "教室",
    meaning: "lớp học, phòng học",
  },
  {
    hiragana: "しょくどう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "食堂",
    meaning: "nhà ăn",
  },
  {
    hiragana: "じむしょ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "事務所",
    meaning: "văn phòng",
  },
  {
    hiragana: "かいぎしつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "会議室",
    meaning: "phòng họp",
  },
  {
    hiragana: "うけつけ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "受付",
    meaning: "bộ phận tiếp tân, phòng thường trực, lễ tân",
  },
  {
    hiragana: "ロビー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "hành lang, đại sảnh",
  },
  {
    hiragana: "へや",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "部屋",
    meaning: "căn phòng",
  },
  {
    hiragana: "トイレ（おてあらい）",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "(お手洗い）",
    meaning: "nhà vệ sinh, phòng vệ sinh, toa-lét",
  },
  {
    hiragana: "かいだん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "階段",
    meaning: "cầu thang",
  },
  {
    hiragana: "エレベーター",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "thang máy",
  },
  {
    hiragana: "エスカレーター",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "thang cuốn",
  },
  {
    hiragana: "[お]くに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "[お]国",
    meaning: "đất nước",
  },
  {
    hiragana: "かいしゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "会社",
    meaning: "công ty",
  },
  {
    hiragana: "うち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "nhà",
  },
  {
    hiragana: "でんわ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "電話",
    meaning: "máy điện thoại, điện thoại",
  },
  {
    hiragana: "くつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "靴",
    meaning: "giày",
  },
  {
    hiragana: "ネクタイ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "cà vạt",
  },
  {
    hiragana: "ワイン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "rượu vang",
  },
  {
    hiragana: "たばこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "thuốc lá",
  },
  {
    hiragana: "うりば",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "売り場",
    meaning: "quầy bán (trong một cửa hàng bách hóa)",
  },
  {
    hiragana: "ちか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "地下",
    meaning: "tầng hầm, dưới mặt đất",
  },
  {
    hiragana: "―かい（－がい）",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "－階",
    meaning: "tầng thứ –",
  },
  {
    hiragana: "なんがい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "何階",
    meaning: "tầng mấy",
  },
  {
    hiragana: "―えん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "―円",
    meaning: "yên",
  },
  {
    hiragana: "いくら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "bao nhiêu tiền",
  },
  {
    hiragana: "ひゃく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "百",
    meaning: "trăm",
  },
  {
    hiragana: "せん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "千",
    meaning: "nghìn",
  },
  {
    hiragana: "まん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "万",
    meaning: "mười nghìn, vạn",
  },
  {
    hiragana: "すみません",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "Xin lỗi",
  },
  {
    hiragana: "～でございます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "(cách nói lịch sự của 「です」)",
  },
  {
    hiragana: "みせてください",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "[～を]見せてください",
    meaning: "cho tôi xem [~]",
  },
  {
    hiragana: "じゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "Thế thì, vậy thì",
  },
  {
    hiragana: "[～を]ください",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "cho tôi [~]",
  },
  {
    hiragana: "しんおおさか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "新大阪",
    meaning: "tên một nhà ga ở Osaka",
  },
  {
    hiragana: "イタリア",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "Ý",
  },
  {
    hiragana: "スイス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "Thụy Sĩ",
  },
  {
    hiragana: "ＭＴ/ヨーネン/アキックス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: " ",
    meaning: "tên các công ty giả tưởng",
  },
  {
    hiragana: "こ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "そ",
    meaning: "あ",
  },
  {
    hiragana: "これ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "それ",
    meaning: "あれ",
  },
  {
    hiragana: "このN",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "そのN",
    meaning: "あのN",
  },
  {
    hiragana: "ここ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "そこ",
    meaning: "あそこ",
  },
  {
    hiragana: "こちら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT3_N5,
    kanji: "そちら",
    meaning: "あちら",
  },
];

export const vocabularyUnit4: IWordOrSentence[] = [
  {
    hiragana: "おきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "thức dậy",
  },
  {
    hiragana: "ねます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "寝ます",
    meaning: "ngủ",
  },
  {
    hiragana: "はたらきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "働きます",
    meaning: "làm việc",
  },
  {
    hiragana: "やすみます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "休みます",
    meaning: "nghỉ ngơi",
  },
  {
    hiragana: "べんきょうします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "勉強します",
    meaning: "học tập",
  },
  {
    hiragana: "おわります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "終わります",
    meaning: "kết thúc",
  },
  {
    hiragana: "デパート",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "cửa hàng bách hóa",
  },
  {
    hiragana: "ぎんこう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "銀行",
    meaning: "ngân hàng",
  },
  {
    hiragana: "ゆうびんきょく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "郵便局",
    meaning: "bưu điện",
  },
  {
    hiragana: "としょかん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "図書館",
    meaning: "thư viện",
  },
  {
    hiragana: "びじゅつかん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "美術館",
    meaning: "viện bảo tàng",
  },
  {
    hiragana: "でんわばんごう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "電話番号",
    meaning: "số điện thoại",
  },
  {
    hiragana: "なんばん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "何番",
    meaning: "số mấy",
  },
  {
    hiragana: "いま",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "今",
    meaning: "bây giờ",
  },
  {
    hiragana: "～じ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "～時",
    meaning: "~giờ",
  },
  {
    hiragana: "～ふん / ～ぷん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "～分",
    meaning: "～phút",
  },
  {
    hiragana: "はん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "半",
    meaning: "phân nửa",
  },
  {
    hiragana: "なんじ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "何時",
    meaning: "mấy giờ",
  },
  {
    hiragana: "なんぷん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "何分",
    meaning: "mấy phút",
  },
  {
    hiragana: "ごぜん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "午前",
    meaning: "sáng (AM: trước 12 giờ)",
  },
  {
    hiragana: "ごご",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "午後",
    meaning: "chiều (PM: sau 12 giờ)",
  },
  {
    hiragana: "あさ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "朝",
    meaning: "sáng",
  },
  {
    hiragana: "ひる",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "昼",
    meaning: "trưa",
  },
  {
    hiragana: "ばん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "晩",
    meaning: "tối",
  },
  {
    hiragana: "よる",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "夜",
    meaning: "tối",
  },
  {
    hiragana: "おととい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "ngày hôm kia",
  },
  {
    hiragana: "きのう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "ngày hôm qua",
  },
  {
    hiragana: "きょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "今日",
    meaning: "hôm nay",
  },
  {
    hiragana: "あした",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "明日",
    meaning: "ngày mai",
  },
  {
    hiragana: "あさって",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "ngày mốt",
  },
  {
    hiragana: "けさ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "今朝",
    meaning: "sáng nay",
  },
  {
    hiragana: "こんばん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "tối nay",
  },
  {
    hiragana: "ゆうべ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "tối hôm qua",
  },
  {
    hiragana: "やすみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "休み",
    meaning: "nghỉ ngơi (danh từ)",
  },
  {
    hiragana: "ひるやすみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "昼休み",
    meaning: "nghỉ trưa",
  },
  {
    hiragana: "まいあさ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "毎朝",
    meaning: "mỗi sáng",
  },
  {
    hiragana: "まいばん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "毎晩",
    meaning: "mỗi tối",
  },
  {
    hiragana: "まいにち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "毎日",
    meaning: "mỗi ngày",
  },
  {
    hiragana: "ペキン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "Bắc Kinh",
  },
  {
    hiragana: "バンコク",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "Bangkok",
  },
  {
    hiragana: "ロンドン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "Luân Đôn",
  },
  {
    hiragana: "ロサンゼルス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "Los Angeles",
  },
  {
    hiragana: "たいへんですね",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: "大変ですね",
    meaning: "vất vả nhỉ",
  },
  {
    hiragana: "ばんごうあんない",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "dịch vụ 116 (hỏi số điện thoại)",
  },
  {
    hiragana: "おといあわせ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT4_N5,
    kanji: " ",
    meaning: "(số điện thoại) bạn muốn biết / hỏi là",
  },
];

export const vocabularyUnit5: IWordOrSentence[] = [
  {
    hiragana: "ばんせん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "―番線",
    meaning: "sân ga số –",
  },
  {
    hiragana: "いきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "行きます",
    meaning: "đi",
  },
  {
    hiragana: "きます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "来ます",
    meaning: "đến",
  },
  {
    hiragana: "かえります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "帰ります",
    meaning: "về",
  },
  {
    hiragana: "がっこう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "学校",
    meaning: "trường học",
  },
  {
    hiragana: "スーパー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: " ",
    meaning: "siêu thị",
  },
  {
    hiragana: "えき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "駅",
    meaning: "ga, nhà ga",
  },
  {
    hiragana: "ひこうき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "飛行機",
    meaning: "máy bay",
  },
  {
    hiragana: "ふね",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "船",
    meaning: "thuyền, tàu thủy",
  },
  {
    hiragana: "でんしゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "電車",
    meaning: "tàu điện",
  },
  {
    hiragana: "ちかてつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "地下鉄",
    meaning: "tàu điện ngầm",
  },
  {
    hiragana: "しんかんせん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "新幹線",
    meaning: "tàu Shinkansen (tàu điện siêu tốc của Nhật)",
  },
  {
    hiragana: "バス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: " ",
    meaning: "xe Buýt",
  },
  {
    hiragana: "タクシー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: " ",
    meaning: "tắc-xi",
  },
  {
    hiragana: "じてんしゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "自転車",
    meaning: "xe đạp",
  },
  {
    hiragana: "あるいて",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "歩いて",
    meaning: "đi bộ",
  },
  {
    hiragana: "ひと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "人",
    meaning: "người",
  },
  {
    hiragana: "ともだち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "友達",
    meaning: "bạn, bạn bè",
  },
  {
    hiragana: "かれ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "彼",
    meaning: "anh ấy, bạn trai",
  },
  {
    hiragana: "かのじょ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "彼女",
    meaning: "chị ấy, bạn gái",
  },
  {
    hiragana: "かぞく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "家族",
    meaning: "gia đình",
  },
  {
    hiragana: "せんしゅう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "先週",
    meaning: "tuần trước",
  },
  {
    hiragana: "こんしゅう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "今週",
    meaning: "tuần này",
  },
  {
    hiragana: "らいしゅう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "来週",
    meaning: "tuần sau",
  },
  {
    hiragana: "せんげつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "先月",
    meaning: "tháng trước",
  },
  {
    hiragana: "こんげつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "今月",
    meaning: "tháng này",
  },
  {
    hiragana: "らいげつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "来月",
    meaning: "tháng sau",
  },
  {
    hiragana: "きょねん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "去年",
    meaning: "năm ngoái",
  },
  {
    hiragana: "ことし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: " ",
    meaning: "năm nay",
  },
  {
    hiragana: "らいねん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "来年",
    meaning: " năm sau",
  },
  {
    hiragana: "―がつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "―月",
    meaning: "tháng –",
  },
  {
    hiragana: "なんがつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "何月",
    meaning: "tháng mấy",
  },
  {
    hiragana: "ついたち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "１日",
    meaning: "ngày mồng 1",
  },
  {
    hiragana: "ふつか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "２日",
    meaning: "ngày mồng 2, 2 ngày",
  },
  {
    hiragana: "みっか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "３日",
    meaning: "ngày mồng 3, 3 ngày",
  },
  {
    hiragana: "よっか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "４日",
    meaning: "ngày mồng 4, 4 ngày",
  },
  {
    hiragana: "いつか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "５日",
    meaning: "ngày mồng 5, 5 ngày",
  },
  {
    hiragana: "むいか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "６日",
    meaning: "ngày mồng 6, 6 ngày",
  },
  {
    hiragana: "なのか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "７日",
    meaning: "ngày mồng 7, 7 ngày",
  },
  {
    hiragana: "ようか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "８日",
    meaning: "ngày mồng 8, 8 ngày",
  },
  {
    hiragana: "ここのか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "９日",
    meaning: "ngày mồng 9, 9 ngày",
  },
  {
    hiragana: "とおか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "１０日",
    meaning: "ngày mồng 10, 10 ngày",
  },
  {
    hiragana: "じゅうよっか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "１４日",
    meaning: "ngày 14, 14 ngày",
  },
  {
    hiragana: "はつか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "２０日",
    meaning: "ngày 20, 20 ngày",
  },
  {
    hiragana: "にじゅうよっか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "２４日",
    meaning: "ngày 24, 24 ngày",
  },
  {
    hiragana: "―にち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "―日",
    meaning: "ngày -, – ngày",
  },
  {
    hiragana: "なんにち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "何日",
    meaning: "ngày mấy, ngày bao nhiêu, mấy ngày, bao nhiêu ngày",
  },
  {
    hiragana: "いつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: " ",
    meaning: "bao giờ, khi nào",
  },
  {
    hiragana: "たんじょうび",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "誕生日",
    meaning: "sinh nhật",
  },
  {
    hiragana: "ふつう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "普通",
    meaning: "tàu thường (dừng cả ở các ga lẻ)",
  },
  {
    hiragana: "きゅうこう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "急行",
    meaning: "tàu tốc hành",
  },
  {
    hiragana: "とっきゅう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "特急",
    meaning: "tàu tốc hành đặc biệt",
  },
  {
    hiragana: "つぎの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: "次の",
    meaning: "tiếp theo",
  },
  {
    hiragana: "どういたしまして",
    total: 0,
    right: 0,
    topic: ETopic.UNIT5_N5,
    kanji: " ",
    meaning: "Không có chi",
  },
];
export const vocabularyUnit6: IWordOrSentence[] = [
  {
    hiragana: "たべます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "食べます",
    meaning: "ăn",
  },
  {
    hiragana: "のみます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "飲みます",
    meaning: "uống",
  },
  {
    hiragana: "すいます [たばこを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "吸います",
    meaning: "hút [thuốc lá]",
  },
  {
    hiragana: "みます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "見ます",
    meaning: "xem, nhìn, trông",
  },
  {
    hiragana: "ききます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "  聞きます",
    meaning: "nghe",
  },
  {
    hiragana: "よみます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "読みます",
    meaning: "đọc",
  },
  {
    hiragana: "かきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "書きます",
    meaning: "viết, vẽ",
  },
  {
    hiragana: "かいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "買います",
    meaning: "mua",
  },
  {
    hiragana: "とります [しゃしんを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "撮ります [写真を～]",
    meaning: "chụp [ảnh]",
  },
  {
    hiragana: "します",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "làm",
  },
  {
    hiragana: "あいます [ともだちに～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "会います [友達に～]",
    meaning: "gặp [bạn]",
  },
  {
    hiragana: "ごはん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "cơm, bữa ăn",
  },
  {
    hiragana: "あさごはん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "朝ごはん",
    meaning: "cơm sáng",
  },
  {
    hiragana: "ひるごはん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "昼ごはん",
    meaning: "cơm trưa",
  },
  {
    hiragana: "ばんごはん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "晩ごはん",
    meaning: "cơm tối",
  },
  {
    hiragana: "パン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "bánh mì",
  },
  {
    hiragana: "たまご",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "卵",
    meaning: "trứng",
  },
  {
    hiragana: "にく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "肉",
    meaning: "thịt",
  },
  {
    hiragana: "さかな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "魚",
    meaning: "cá",
  },
  {
    hiragana: "やさい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "野菜",
    meaning: "rau",
  },
  {
    hiragana: "くだもの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "果物",
    meaning: "hoa quả, trái cây",
  },
  {
    hiragana: "みず",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "水",
    meaning: "nước",
  },
  {
    hiragana: "おちゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "お茶",
    meaning: "trà (nói chung)",
  },
  {
    hiragana: "こうちゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "紅茶",
    meaning: "trà đen",
  },
  {
    hiragana: "ぎゅうにゅう (ミルク)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "牛乳",
    meaning: "sữa bò",
  },
  {
    hiragana: "ジュース",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "nước hoa quả",
  },
  {
    hiragana: "ビール",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "bia",
  },
  {
    hiragana: "[お]さけ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "[お]酒",
    meaning: "rượu, rượu sake",
  },
  {
    hiragana: "ビデオ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "video, băng video, đầu video",
  },
  {
    hiragana: "えいが",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "映画",
    meaning: " phim, điện ảnh",
  },
  {
    hiragana: "ＣＤ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "đĩa CD",
  },
  {
    hiragana: "てがみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "手紙",
    meaning: "thư",
  },
  {
    hiragana: "レポート",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "báo cáo",
  },
  {
    hiragana: "しゃしん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "写真",
    meaning: "ảnh",
  },
  {
    hiragana: "みせ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "店",
    meaning: "cửa hàng, tiệm",
  },
  {
    hiragana: "レストラン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "nhà hàng",
  },
  {
    hiragana: "にわ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "庭",
    meaning: "vườn",
  },
  {
    hiragana: "しゅくだい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "宿題",
    meaning: "bài tập về nhà (～をします: làm bài tập)",
  },
  {
    hiragana: "テニス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "quần vợt (～をします: đánh quần vợt)",
  },
  {
    hiragana: "サッカー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "bóng đá (～をします: chơi bóng đá)",
  },
  {
    hiragana: "[お]はなみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "[お]花見",
    meaning: "việc ngắm hoa anh đào (～をします: ngắm hoa anh đào)",
  },
  {
    hiragana: "なに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "何",
    meaning: "cái gì, gì",
  },
  {
    hiragana: "いっしょに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "cùng, cùng nhau",
  },
  {
    hiragana: "ちょっと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "một chút",
  },
  {
    hiragana: "いつも",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "luôn luôn, lúc nào cũng",
  },
  {
    hiragana: "ときどき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "時々",
    meaning: "thỉnh thoảng",
  },
  {
    hiragana: "それから",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "sau đó, tiếp theo",
  },
  {
    hiragana: "ええ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "vâng, được (cách nói thân mật của 「はい」)",
  },
  {
    hiragana: "いいですね。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "Được đấy nhỉ./ hay quá.",
  },
  {
    hiragana: "わかりました。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "Tôi hiểu rồi/ vâng ạ.",
  },
  {
    hiragana: "なんですか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: "何ですか。",
    meaning:
      "Có gì đấy ạ?/ cái gì vậy?/ vâng có tôi. (câu trả lời khi ai đó gọi tên mình)",
  },
  {
    hiragana: "じゃ、また[あした]。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "Hẹn gặp lại [ngày mai].",
  },
  {
    hiragana: "メキシコ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT6_N5,
    kanji: " ",
    meaning: "Mexico",
  },
];
export const vocabularyUnit7: IWordOrSentence[] = [
  {
    hiragana: "きる",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "切る",
    meaning: "cắt",
  },
  {
    hiragana: "おくる",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "送る",
    meaning: "gửi",
  },
  {
    hiragana: "あげる",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "tặng",
  },
  {
    hiragana: "もらう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "nhận",
  },
  {
    hiragana: "かす",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "  貸す",
    meaning: "cho mượn",
  },
  {
    hiragana: "かりる",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "借りる",
    meaning: "mượn",
  },
  {
    hiragana: "かける",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "mang",
  },
  {
    hiragana: "て",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "手",
    meaning: "tay",
  },
  {
    hiragana: "はし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "cầu",
  },
  {
    hiragana: "スプーン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "muỗng",
  },
  {
    hiragana: "ナイフ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "con dao",
  },
  {
    hiragana: "フォーク",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "nĩa",
  },
  {
    hiragana: "はさみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "cái kéo",
  },
  {
    hiragana: "ファクス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "máy fax",
  },
  {
    hiragana: "ワープロ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "máy đánh chữ",
  },
  {
    hiragana: "パソコン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "máy tính",
  },
  {
    hiragana: "パンチ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: " cái đục lỗ",
  },
  {
    hiragana: "ホッチキス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: " cái dập ghim",
  },
  {
    hiragana: "セロテープ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "băng dính",
  },
  {
    hiragana: "けしゴム",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "cục tẩy",
  },
  {
    hiragana: "かみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "tóc",
  },
  {
    hiragana: "はな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "花",
    meaning: "hoa",
  },
  {
    hiragana: "シャツ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "áo sơ mi",
  },
  {
    hiragana: "プレゼント",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "quà tặng",
  },
  {
    hiragana: "にもつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "荷物",
    meaning: "hàng hóa",
  },
  {
    hiragana: "おかね",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "お金",
    meaning: "tiền",
  },
  {
    hiragana: "きっぷ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "vé (tàu ,xe)",
  },
  {
    hiragana: "クリスマス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "Lễ Giáng sinh",
  },
  {
    hiragana: "ちち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "父",
    meaning: "cha mình",
  },
  {
    hiragana: "はは",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "母",
    meaning: " mẹ mình",
  },
  {
    hiragana: "これから",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "từ bây giờ-",
  },
  {
    hiragana: "おとうさん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "お父さん",
    meaning: "cha ( người khác)",
  },
  {
    hiragana: "おかあさん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "お母さん",
    meaning: "mẹ (\bngười khác)",
  },
  {
    hiragana: "もう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "đã rồi",
  },
  {
    hiragana: "まだ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "vẫn còn , vẫn chưa",
  },
  {
    hiragana: "ごめん‌な‌さい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "xin lỗi",
  },
  {
    hiragana: "いらっしゃい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "xin mời",
  },
  {
    hiragana: "いってきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "tôi đi đây",
  },
  {
    hiragana: "しつれします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "xin thất lễ",
  },
  {
    hiragana: "りょこう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "旅行",
    meaning: "du lịch",
  },
  {
    hiragana: "おみやげ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: "お土産",
    meaning: "quà lưu niệm",
  },
  {
    hiragana: "ヨーロッパ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT7_N5,
    kanji: " ",
    meaning: "Châu Âu",
  },
];
export const vocabularyUnit8: IWordOrSentence[] = [
  {
    hiragana: "みにくい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "Xấu",
  },
  {
    hiragana: "ハンサム(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "đẹp trai",
  },
  {
    hiragana: "きれい(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "(cảnh) đẹp, đẹp (gái), sạch",
  },
  {
    hiragana: "しずか(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "静か(な)",
    meaning: "yên tĩnh",
  },
  {
    hiragana: "にぎやか(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "賑やか(な)",
    meaning: "nhộn nhịp",
  },
  {
    hiragana: "ゆうめい(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "有名(な)",
    meaning: "nổi tiếng",
  },
  {
    hiragana: "しんせつ(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "親切(な)",
    meaning: "tử tế",
  },
  {
    hiragana: "げんき(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "元気(な)",
    meaning: "khỏe",
  },
  {
    hiragana: "ひま(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "暇(な)",
    meaning: "rảnh rỗi",
  },
  {
    hiragana: "いそがしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "忙しい",
    meaning: "bận rộn",
  },
  {
    hiragana: "べんり(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "便利(な)",
    meaning: "tiện lợi",
  },
  {
    hiragana: "すてき(な)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "tuyệt vời",
  },
  {
    hiragana: "おおきい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "大きい",
    meaning: "to, lớn",
  },
  {
    hiragana: "ちいさい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "小さい",
    meaning: "nhỏ, bé",
  },
  {
    hiragana: "あたらしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "新しい",
    meaning: "mới",
  },
  {
    hiragana: "ふるい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "古い",
    meaning: "cũ",
  },
  {
    hiragana: "いい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "tốt",
  },
  {
    hiragana: "わるい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "悪い",
    meaning: "xấu",
  },
  {
    hiragana: "あつい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "熱い",
    meaning: "nóng",
  },
  {
    hiragana: "つめたい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "冷たい",
    meaning: "lạnh",
  },
  {
    hiragana: "あつい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "暑い",
    meaning: "(trời) nóng (dùng cho thời tiết)",
  },
  {
    hiragana: "さむい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "寒い",
    meaning: "(trời) lạnh (dùng cho thời tiết)",
  },
  {
    hiragana: "むずかしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "難しい",
    meaning: "khó",
  },
  {
    hiragana: "やさしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "優しい",
    meaning: "dễ",
  },
  {
    hiragana: "きびしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "nghiêm khắc",
  },
  {
    hiragana: "やさしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "dịu dàng, hiền từ",
  },
  {
    hiragana: "たかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "高い",
    meaning: "đắt",
  },
  {
    hiragana: "やすい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "安い",
    meaning: "rẻ",
  },
  {
    hiragana: "ひくい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "低い",
    meaning: "thấp",
  },
  {
    hiragana: "たかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "高い",
    meaning: " cao",
  },
  {
    hiragana: "おもしろい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "thú vị",
  },
  {
    hiragana: "おいしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "ngon",
  },
  {
    hiragana: "たのしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "楽しい",
    meaning: "vui vẻ",
  },
  {
    hiragana: "しろい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "白い",
    meaning: "trắng",
  },
  {
    hiragana: "くろい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "黒い",
    meaning: "đen",
  },
  {
    hiragana: "あかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "赤い",
    meaning: "đỏ",
  },
  {
    hiragana: "あおい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "青い",
    meaning: "xanh",
  },
  {
    hiragana: "さくら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "桜",
    meaning: "hoa anh đào",
  },
  {
    hiragana: "やま",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "山",
    meaning: "núi",
  },
  {
    hiragana: "まち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "町",
    meaning: "thành phố",
  },
  {
    hiragana: "たべもの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "食べ物",
    meaning: "thức ăn",
  },
  {
    hiragana: "ところ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "所",
    meaning: "chỗ",
  },
  {
    hiragana: "りょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "ký túc xá",
  },
  {
    hiragana: "べんきょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "勉強",
    meaning: "học tập ( danh từ )",
  },
  {
    hiragana: "せいかつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "生活",
    meaning: "cuộc sống",
  },
  {
    hiragana: "(お)しごと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: "お仕事",
    meaning: "công việc",
  },
  {
    hiragana: "どう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "như thế nào",
  },
  {
    hiragana: "どんな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "～nào",
  },
  {
    hiragana: "どれ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: "cái nào",
  },
  {
    hiragana: " ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT8_N5,
    kanji: " ",
    meaning: " ",
  },
];
export const vocabularyUnit9: IWordOrSentence[] = [
  {
    hiragana: "わかります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "分かります",
    meaning: "hiểu, nắm được",
  },
  {
    hiragana: "あります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "có (sở hữu)",
  },
  {
    hiragana: "すき[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "好き[な]",
    meaning: "thích",
  },
  {
    hiragana: "きらい[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "嫌い[な] )",
    meaning: "ghét, không thích",
  },
  {
    hiragana: "じょうず[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "上手[な]",
    meaning: "giỏi, khéo",
  },
  {
    hiragana: "へた[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "下手[な]",
    meaning: "kém",
  },
  {
    hiragana: "りょうり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "料理",
    meaning: "món ăn, việc nấu ăn",
  },
  {
    hiragana: "のみもの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "飲み物",
    meaning: "đồ uống",
  },
  {
    hiragana: "スポーツ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "thể thao (～をします：chơi thể thao)",
  },
  {
    hiragana: "やきゅう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "野球",
    meaning: "bóng chày (～をします：chơi bóng chày)",
  },
  {
    hiragana: "ダンス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "nhảy, khiêu vũ (～をします：nhảy, khiêu vũ)",
  },
  {
    hiragana: "おんがく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "音楽",
    meaning: "âm nhạc",
  },
  {
    hiragana: "うた",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "歌",
    meaning: "bài hát",
  },
  {
    hiragana: "クラシック",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "nhạc cổ điển",
  },
  {
    hiragana: "ジャズ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "nhạc jazz",
  },
  {
    hiragana: "コンサート",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "buổi hòa nhạc",
  },
  {
    hiragana: "カラオケ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "karaoke",
  },
  {
    hiragana: "かぶき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "歌舞伎",
    meaning: "Kabuki (một loại ca kịch truyền thống của Nhật)",
  },
  {
    hiragana: "え",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "絵",
    meaning: "Tranh, hội họa",
  },
  {
    hiragana: "じ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "字",
    meaning: "chữ",
  },
  {
    hiragana: "かんじ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "漢字",
    meaning: "chữ hán",
  },
  {
    hiragana: "ひらがな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "chữ Hiragana",
  },
  {
    hiragana: "かたかな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "Chữ Katakana",
  },
  {
    hiragana: "ローマじ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "ローマ字",
    meaning: "chữ La Mã",
  },
  {
    hiragana: "こまかいおかね",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "細かいお金",
    meaning: "tiền lẻ",
  },
  {
    hiragana: "チケット",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "vé (xem hòa nhạc, xem phim)",
  },
  {
    hiragana: "じかん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "時間",
    meaning: "thời gian",
  },
  {
    hiragana: "ようじ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "用事",
    meaning: "việc bận, công chuyện",
  },
  {
    hiragana: "やくそく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "約束",
    meaning: "cuộc hẹn, lời hứa",
  },
  {
    hiragana: "ごしゅじん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "ご‌主‌人",
    meaning: "chồng (dùng khi nói về chồng người khác)",
  },
  {
    hiragana: "おっと/しゅじん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "夫/主人",
    meaning: "chồng (dùng khi nói về chồng mình)",
  },
  {
    hiragana: "おくさん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "奥さん",
    meaning: "vợ (dùng khi nói về vợ người khác)",
  },
  {
    hiragana: "つま/かない",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "妻/家内",
    meaning: "vợ (dùng khi nói về vợ mình)",
  },
  {
    hiragana: "こども",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "子ども",
    meaning: "con cái",
  },
  {
    hiragana: "よく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "tốt, rõ (chỉ mức độ)",
  },
  {
    hiragana: "だいたい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "đại khái, đại thể",
  },
  {
    hiragana: "たくさん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "nhiều",
  },
  {
    hiragana: "すこし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "少し",
    meaning: "ít, một ít",
  },
  {
    hiragana: "ぜんぜん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "全然",
    meaning: "hoàn toàn ~ không",
  },
  {
    hiragana: "はやく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "早く、速く",
    meaning: "sớm, nhanh",
  },
  {
    hiragana: "～から",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "vì ~",
  },
  {
    hiragana: "どうして",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "tại sao",
  },
  {
    hiragana: "ざんねんです[ね]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: "残念です[ね]",
    meaning: "Thật đáng tiếc nhỉ/ buồn nhỉ",
  },
  {
    hiragana: "すみません",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "Xin lỗi",
  },
  {
    hiragana: "もしもし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "a-lô",
  },
  {
    hiragana: "ああ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "a (cách nói khi đã gặp được đúng người trên điện thoại)",
  },
  {
    hiragana: "いっしょにいかがですか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "Anh/chị cùng ~ (làm cái gì đó) với chúng tôi được không?",
  },
  {
    hiragana: "[～は]ちょっと….",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning:
      "[~ thì] có lẽ không được rồi. (cách từ chối khéo khi nhận được một lời mời nào đó)",
  },
  {
    hiragana: "だめですか。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "Không được à?",
  },
  {
    hiragana: "またこんどおねがいします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT9_N5,
    kanji: " ",
    meaning: "hẹn lần sau",
  },
];
export const vocabularyUnit10: IWordOrSentence[] = [
  {
    hiragana: "います",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "có, ở (tồn tại, dùng cho người và động vật)",
  },
  {
    hiragana: "あります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "có (tồn tại, dùng cho đồ vật)",
  },
  {
    hiragana: "いろいろ[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "nhiều, đa dạng",
  },
  {
    hiragana: "おとこのひと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "男の人",
    meaning: "người đàn ông",
  },
  {
    hiragana: "じょうず[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "上手[な]",
    meaning: "giỏi, khéo",
  },
  {
    hiragana: "おんなのひと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "女の人",
    meaning: "người đàn bà",
  },
  {
    hiragana: "おとこのこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "男の子",
    meaning: "cậu con trai",
  },
  {
    hiragana: "おんなのこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "女の子",
    meaning: "cô con gái",
  },
  {
    hiragana: "いぬ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "犬",
    meaning: "chó",
  },
  {
    hiragana: "ねこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "猫",
    meaning: "mèo",
  },
  {
    hiragana: "き",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "木",
    meaning: "cây, gỗ",
  },
  {
    hiragana: "もの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "物",
    meaning: "vật, đồ vật",
  },
  {
    hiragana: "フィルム",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "phim",
  },
  {
    hiragana: "でんち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "電池",
    meaning: "Pin",
  },
  {
    hiragana: "はこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "箱",
    meaning: "hộp",
  },
  {
    hiragana: "スイッチ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "công tắc",
  },
  {
    hiragana: "れいぞうこ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "冷蔵庫",
    meaning: "tủ lạnh",
  },
  {
    hiragana: "テーブル",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "bàn",
  },
  {
    hiragana: "ベッド",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "giường",
  },
  {
    hiragana: "たな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "棚",
    meaning: "giá sách",
  },
  {
    hiragana: "ドア",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "cửa",
  },
  {
    hiragana: "まど",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "窓",
    meaning: "cửa sổ",
  },
  {
    hiragana: "ポスト",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "hộp thư, hòm thư",
  },
  {
    hiragana: "ビル",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "toà nhà",
  },
  {
    hiragana: "こうえん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "公園",
    meaning: "công viên",
  },
  {
    hiragana: "きっさてん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "喫茶店",
    meaning: "quán giải khát, quán cà-phê",
  },
  {
    hiragana: "ほんや",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "本屋",
    meaning: "hiệu sách",
  },
  {
    hiragana: "～や",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "～屋",
    meaning: "hiệu ~, cửa hàng ~",
  },
  {
    hiragana: "のりば",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "乗り場",
    meaning: "bến xe, điểm lên xuống xe",
  },
  {
    hiragana: "けん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "県",
    meaning: " tỉnh",
  },
  {
    hiragana: "うえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "上",
    meaning: "trên",
  },
  {
    hiragana: "した",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "下",
    meaning: "dưới",
  },
  {
    hiragana: "まえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "前",
    meaning: "trước",
  },
  {
    hiragana: "うしろ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "後ろ",
    meaning: "sau",
  },
  {
    hiragana: "みぎ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "右",
    meaning: "phải",
  },
  {
    hiragana: "ひだり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "左",
    meaning: "trái",
  },
  {
    hiragana: "なか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "中",
    meaning: "trong, giữa",
  },
  {
    hiragana: "そと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "外",
    meaning: "ngoài",
  },
  {
    hiragana: "となり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "隣",
    meaning: "bên cạnh",
  },
  {
    hiragana: "ちかく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "近く",
    meaning: "gần",
  },
  {
    hiragana: "あいだ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "間",
    meaning: "giữa",
  },
  {
    hiragana: "～や～[など]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "~ và ~, [v.v.]",
  },
  {
    hiragana: "いちばん～",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "~ nhất (いちばん うえ：vị trí cao nhất)",
  },
  {
    hiragana: "―だんめ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: "―段目",
    meaning: "giá thứ -, tầng thứ – (「だん」 được dùng cho giá sách v.v.)",
  },
  {
    hiragana: "すみません",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "Xin lỗi",
  },
  {
    hiragana: "チリソース",
    total: 0,
    right: 0,
    topic: ETopic.UNIT10_N5,
    kanji: " ",
    meaning: "tương ớt (chili sauce)",
  },
];
export const vocabularyUnit11: IWordOrSentence[] = [
  {
    hiragana: "います",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "có (động vật)",
  },
  {
    hiragana: "かかります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "mất, tốn",
  },
  {
    hiragana: "やすみます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "休みます",
    meaning: "nghỉ ngơi",
  },
  {
    hiragana: "ひとつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "1 cái (đồ vật)",
  },
  {
    hiragana: "ふたつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "2 cái",
  },
  {
    hiragana: "みっつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "3 cái",
  },
  {
    hiragana: "よっつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "4 cái",
  },
  {
    hiragana: "いつつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "5 cái",
  },
  {
    hiragana: "むっつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "6 cái",
  },
  {
    hiragana: "ななつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "7 cái",
  },
  {
    hiragana: "やっつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "8 cái",
  },
  {
    hiragana: "ここのつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "9 cái",
  },
  {
    hiragana: "とお",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "10 cái",
  },
  {
    hiragana: "いくつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "bao nhiêu cái",
  },
  {
    hiragana: "ひとり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "一人",
    meaning: "1 người",
  },
  {
    hiragana: "ふたり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "二人",
    meaning: "2 người",
  },
  {
    hiragana: "～にん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "～人",
    meaning: "～người",
  },
  {
    hiragana: "～だい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "～cái, chiếc (máy móc)",
  },
  {
    hiragana: "～まい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "～tờ, (những vật mỏng như áo, giấy…)",
  },
  {
    hiragana: "～かい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "～lần, tầng lầu",
  },
  {
    hiragana: "りんご",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "quả táo",
  },
  {
    hiragana: "みかん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "quýt",
  },
  {
    hiragana: "サンドイッチ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "sandwich",
  },
  {
    hiragana: "カレー(ライス)",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "(cơm) cà ri",
  },
  {
    hiragana: "アイスクリーム",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " ",
    meaning: "kem",
  },
  {
    hiragana: "きって",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "切手",
    meaning: "tem",
  },
  {
    hiragana: "はがき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "葉書",
    meaning: "bưu thiếp",
  },
  {
    hiragana: "ふうとう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "封筒",
    meaning: "phong bì",
  },
  {
    hiragana: "そくたつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: "速達",
    meaning: "chuyển phát nhanh",
  },
  {
    hiragana: "かきとめ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT11_N5,
    kanji: " 書留",
    meaning: " gửi bảo đảm",
  },
];
export const vocabularyUnit12: IWordOrSentence[] = [
  {
    hiragana: "かんたん「な」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "簡単「な」",
    meaning: "Đơn giản, dễ",
  },
  {
    hiragana: "ちかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "近い",
    meaning: "gần",
  },
  {
    hiragana: "とおい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "遠い",
    meaning: "xa",
  },
  {
    hiragana: "はやい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "速い、早い",
    meaning: "nhanh, sớm",
  },
  {
    hiragana: "おそい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "遅い",
    meaning: "chậm, muộn",
  },
  {
    hiragana: "おおい [ひとが～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "多い [人が～]",
    meaning: "nhiều [người]",
  },
  {
    hiragana: "すくない [ひとが～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "少ない [人が～]",
    meaning: "ít [người]",
  },
  {
    hiragana: "あたたかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "温かい、暖かい",
    meaning: "ấm",
  },
  {
    hiragana: "すずしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "涼しい",
    meaning: "mát",
  },
  {
    hiragana: "あまい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "甘い",
    meaning: "ngọt",
  },
  {
    hiragana: "からい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "辛い",
    meaning: "cay",
  },
  {
    hiragana: "おもい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "重い",
    meaning: "nặng",
  },
  {
    hiragana: "かるい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "軽い",
    meaning: "nhẹ",
  },
  {
    hiragana: "いい [コーヒーが～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "thích, chọn, dùng [cafe]",
  },
  {
    hiragana: "きせつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "季節",
    meaning: "mùa",
  },
  {
    hiragana: "はる",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "春",
    meaning: "mùa xuân",
  },
  {
    hiragana: "なつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "夏",
    meaning: "mùa hè",
  },
  {
    hiragana: "あき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "秋",
    meaning: "mùa thu",
  },
  {
    hiragana: "ふゆ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "冬",
    meaning: "mùa đông",
  },
  {
    hiragana: "てんき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "天‌気",
    meaning: "thời tiết",
  },
  {
    hiragana: "あめ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "雨",
    meaning: "mưa",
  },
  {
    hiragana: "ゆき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "雪",
    meaning: "tuyết",
  },
  {
    hiragana: "くもり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "曇り",
    meaning: "có mây",
  },
  {
    hiragana: "ホテル",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "khách sạn",
  },
  {
    hiragana: "くうこう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "空港",
    meaning: "sân bay",
  },
  {
    hiragana: "うみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "海",
    meaning: "biển, đại dương",
  },
  {
    hiragana: "せかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "世‌界",
    meaning: "thế giới",
  },
  {
    hiragana: "パーティー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "tiệc (~をします：tổ chức tiệc)",
  },
  {
    hiragana: "(お) まつり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "(お) 祭り",
    meaning: "lễ hội",
  },
  {
    hiragana: "しけん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "試験",
    meaning: " kỳ thi, bài thi",
  },
  {
    hiragana: "すきやき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "す‌き‌焼‌き",
    meaning: "Sukiyaki (món thịt bò nấu rau)",
  },
  {
    hiragana: "さしみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "刺身",
    meaning: "Sashimi (món gỏi cá sống)",
  },
  {
    hiragana: "「お」すし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Sushi",
  },
  {
    hiragana: "てんぷら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Tempura (món hải sản và rau chiên tẩm bột",
  },
  {
    hiragana: "いけばな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "生け花",
    meaning: "Nghệ thuật cắm hoa (～をします：cắm hoa)",
  },
  {
    hiragana: "もみじ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "紅葉",
    meaning: "lá đỏ",
  },
  {
    hiragana: "どちら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "cái nào",
  },
  {
    hiragana: "はじめて",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "初めて",
    meaning: "lần đầu tiên",
  },
  {
    hiragana: "どちらも",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "cả hai",
  },
  {
    hiragana: "ずっと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "(hơn) hẳn (dùng để nhấn mạnh sự khác biệt giữa hai đối tượng)",
  },
  {
    hiragana: "ただいま。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Tôi đã về đây. (dùng nói khi về đến nhà)",
  },
  {
    hiragana: "お帰かえりなさい。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Anh/Chị đã về đấy à. (dùng để nói với ai đó mới về đến nhà)",
  },
  {
    hiragana: "すごいですね。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Ghê quá nhỉ./ hay quá nhỉ.",
  },
  {
    hiragana: "でも",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Nhưng",
  },
  {
    hiragana: "つかれました",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: "疲‌れ‌ま‌し‌た‌",
    meaning: "Tôi mệt rồi",
  },
  {
    hiragana: "ホンコン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Hồng Kông",
  },
  {
    hiragana: "シンガポール",
    total: 0,
    right: 0,
    topic: ETopic.UNIT12_N5,
    kanji: " ",
    meaning: "Singapore",
  },
];
export const vocabularyUnit13: IWordOrSentence[] = [
  {
    hiragana: "あそびます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "遊びます",
    meaning: "chơi",
  },
  {
    hiragana: "およぎます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "泳ぎます",
    meaning: "bơi",
  },
  {
    hiragana: "むかえます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "迎えます",
    meaning: "đón",
  },
  {
    hiragana: "つかれます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "疲れます",
    meaning: "mệt",
  },
  {
    hiragana: "だします [てがみを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "出します [手紙を～]",
    meaning: "gửi[thư]",
  },
  {
    hiragana: "はいります [きっさてんに～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "入ります [喫茶店に～]",
    meaning: "vào [quán giải khát]",
  },
  {
    hiragana: "でます [きっさてんを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "出ます [喫茶店を～]",
    meaning: "ra, ra khỏi [quán giải khát]",
  },
  {
    hiragana: "けっこんします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "結婚します",
    meaning: "kết hôn, lập gia đình, cưới",
  },
  {
    hiragana: "かいものします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "買い物します",
    meaning: "mua hàng",
  },
  {
    hiragana: "しょくじします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "食事します",
    meaning: "ăn cơm",
  },
  {
    hiragana: "さんぽします [こうえんを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "散歩します [公園を～]",
    meaning: "đi dạo [ở công viên]",
  },
  {
    hiragana: "たいへん「な」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "大変「な」",
    meaning: "vất vả, khó khăn, khổ",
  },
  {
    hiragana: "ほしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "欲しい",
    meaning: "muốn có",
  },
  {
    hiragana: "さびしい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "寂しい",
    meaning: "buồn, cô đơn",
  },
  {
    hiragana: "ひろい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "広い",
    meaning: "rộng",
  },
  {
    hiragana: "せまい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "狭い",
    meaning: "chật, hẹp",
  },
  {
    hiragana: "しやくしょ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "市役所",
    meaning: "văn phòng hành chính quận, thành phố",
  },
  {
    hiragana: "プール",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "bể bơi",
  },
  {
    hiragana: "かわ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "川",
    meaning: "sông",
  },
  {
    hiragana: "けいざい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "経済",
    meaning: "kinh tế",
  },
  {
    hiragana: "びじゅつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "美術",
    meaning: "mỹ thuật",
  },
  {
    hiragana: "つり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "釣り",
    meaning: "việc câu cá (~をします：câu cá)",
  },
  {
    hiragana: "スキー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "việc trượt tuyết (~をします： trượt tuyết)",
  },
  {
    hiragana: "かいぎ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "会議",
    meaning: "họp, cuộc họp (~をします: họp, tổ chức cuộc họp)",
  },
  {
    hiragana: "とうろく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "登録",
    meaning: "việc đăng ký (~をします：đăng ký)",
  },
  {
    hiragana: "しゅうまつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "週末",
    meaning: "cuối tuần",
  },
  {
    hiragana: "～ごろ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "Khoảng ~ (dùng cho thời gian)",
  },
  {
    hiragana: "なにか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "何か",
    meaning: "cái gì đó",
  },
  {
    hiragana: "どこか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "đâu đó, chỗ nào đó",
  },
  {
    hiragana: "おなかが すきました。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "(tôi) đói rồi.",
  },
  {
    hiragana: "おなかが いっぱいです。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "(tôi) no rồi.",
  },
  {
    hiragana: "のどが かわきました。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "(tôi) khát.",
  },
  {
    hiragana: "そうですね。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning:
      "Đúng thế. (câu nói khi muốn tỏ thái độ tán thành với người cùng nói chuyện)",
  },
  {
    hiragana: "ロシア",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "Nga",
  },
  {
    hiragana: "そうしましょう。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "Nhất trí./ Chúng ta thống nhất như thế.",
  },
  {
    hiragana: "ていしょく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: " ",
    meaning: "Cơm suất, cơm phần",
  },
  {
    hiragana: "ごちゅうもんは",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "ご注文は",
    meaning: "Mời anh/chị gọi món",
  },
  {
    hiragana: "ぎゅうどん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "牛どん",
    meaning: "món cơm thịt bò",
  },
  {
    hiragana: "しょうしょう　おまちください",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "少々お待ちください。",
    meaning: "xin vui lòng đợi một chút",
  },
  {
    hiragana: "べつべつに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT13_N5,
    kanji: "別々に",
    meaning: "để riêng ra",
  },
];
export const vocabularyUnit14: IWordOrSentence[] = [
  {
    hiragana: "つけます II",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "bật (điện, máy điều hòa)",
  },
  {
    hiragana: "けしますＩ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "消します",
    meaning: "tắt (điện, máy điều hòa)",
  },
  {
    hiragana: "あけます II",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "開けます",
    meaning: "mở (cửa, cửa sổ)",
  },
  {
    hiragana: "しめます II",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "閉めます",
    meaning: "đóng (cửa, cửa sổ)",
  },
  {
    hiragana: "いそぎます I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "急ぎます",
    meaning: "vội, gấp",
  },
  {
    hiragana: "まちます I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "待ちます",
    meaning: "đợi, chờ",
  },
  {
    hiragana: "とめます II",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "止めます",
    meaning: "dừng (băng, ôt ô), đỗ (ôtô)",
  },
  {
    hiragana: "まがります I [みぎへ～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "曲がります [右へ～]",
    meaning: "rẽ, quẹo [phải]",
  },
  {
    hiragana: "もちます I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "持ちます",
    meaning: "mang, cầm",
  },
  {
    hiragana: "とります I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "取ります",
    meaning: "lấy (muối)",
  },
  {
    hiragana: "てつだいます I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "手伝います",
    meaning: "giúp (làm việc)",
  },
  {
    hiragana: "よびます I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "呼びます",
    meaning: "gọi (taxi, tên)",
  },
  {
    hiragana: "はなします I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "話します",
    meaning: "nói, nói chuyện",
  },
  {
    hiragana: "みせます II",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "見せます",
    meaning: "cho xem, trình",
  },
  {
    hiragana: "おしえます II",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "教えます",
    meaning: "nói, cho biết",
  },
  {
    hiragana: "はじめます II",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "始めます",
    meaning: "bắt đầu",
  },
  {
    hiragana: "ふります I",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "降ります",
    meaning: "rơi [mưa, tuyết~]",
  },
  {
    hiragana: "コピーします III",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "copy",
  },
  {
    hiragana: "エアコン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "máy điều hòa",
  },
  {
    hiragana: "パスポート",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "hộ chiếu",
  },
  {
    hiragana: "なまえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "名前",
    meaning: "tên",
  },
  {
    hiragana: "じゅうしょ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "住所",
    meaning: "địa chỉ",
  },
  {
    hiragana: "ちず",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "地図",
    meaning: "bản đồ",
  },
  {
    hiragana: "しお",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "塩",
    meaning: "muối",
  },
  {
    hiragana: "さとう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "砂糖",
    meaning: "đường",
  },
  {
    hiragana: "よみかた",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "読み方",
    meaning: "cách đọc",
  },
  {
    hiragana: "～かた",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "～方",
    meaning: "cách ~",
  },
  {
    hiragana: "ゆっくり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "chậm, thong thả, thoải mái",
  },
  {
    hiragana: "すぐ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "ngay, lập tức",
  },
  {
    hiragana: "また",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: " lại (~đến)",
  },
  {
    hiragana: "あとで",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "sau",
  },
  {
    hiragana: "もう すこし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "もう 少し",
    meaning: "thêm một chút nữa thôi",
  },
  {
    hiragana: "もう～",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "thêm~",
  },
  {
    hiragana: "いいですよ。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "Được chứ./được ạ.",
  },
  {
    hiragana: "さあ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "thôi,/nào, (dùng để thúc giục hoặc khuyến khích ai làm gì.)",
  },
  {
    hiragana: "あれ？",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning:
      "Ô! (câu cảm thán khi phát hiện hoặc thấy cái gì đó lạ, hoặc bất ngờ)",
  },
  {
    hiragana: "まっすぐ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: " ",
    meaning: "thẳng",
  },
  {
    hiragana: "おつり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "お釣り",
    meaning: "tiền lẻ",
  },
  {
    hiragana: "これでおねがいします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "これでお願いします",
    meaning: "gửi anh tiền này",
  },
  {
    hiragana: "貸します",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "待ちます",
    meaning: "来ます",
  },
  {
    hiragana: "泳ぎます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT14_N5,
    kanji: "食べます",
    meaning: "遊びます",
  },
];
export const vocabularyUnit15: IWordOrSentence[] = [
  {
    hiragana: "たちます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "立ちます",
    meaning: "đứng",
  },
  {
    hiragana: "すわります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "座ります",
    meaning: "ngồi",
  },
  {
    hiragana: "つかいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "使います",
    meaning: "dùng, sử dụng",
  },
  {
    hiragana: "おきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "置きます",
    meaning: "đặt, để",
  },
  {
    hiragana: "つくります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "作ります，造ります",
    meaning: "làm, chế tạo, sản xuất",
  },
  {
    hiragana: "うります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "売ります",
    meaning: "bán",
  },
  {
    hiragana: "しります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "知ります",
    meaning: "biết",
  },
  {
    hiragana: "すみます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "住みます",
    meaning: "sống, ở",
  },
  {
    hiragana: "けんきゅうします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "研究します",
    meaning: "nghiên cứu",
  },
  {
    hiragana: "しって います",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "知って います",
    meaning: "biết",
  },
  {
    hiragana: "すんで います [おおさかに～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "住んで います [大阪に～]",
    meaning: "sống [ở Osaka]",
  },
  {
    hiragana: "しりょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "資料",
    meaning: "tài liệu, tư liệu",
  },
  {
    hiragana: "カタログ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: " ",
    meaning: "ca-ta-lô",
  },
  {
    hiragana: "じこくひょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "時刻表",
    meaning: "bảng giờ tàu chạy",
  },
  {
    hiragana: "ふく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "服",
    meaning: "quần áo",
  },
  {
    hiragana: "せいひん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "製品",
    meaning: "sản phẩm",
  },
  {
    hiragana: "ソフト",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: " ",
    meaning: "phần mềm",
  },
  {
    hiragana: "せんもん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "専門",
    meaning: "chuyên môn",
  },
  {
    hiragana: "はいしゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "歯医者",
    meaning: "nha sĩ",
  },
  {
    hiragana: "とこや",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "床屋",
    meaning: "hiệu cắt tóc",
  },
  {
    hiragana: "プレイガイド",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: " ",
    meaning: "quầy bán vé (trong nhà hát)",
  },
  {
    hiragana: "どくしん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "独身",
    meaning: "độc thân",
  },
  {
    hiragana: "とくに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: "特に",
    meaning: "đặc biệt",
  },
  {
    hiragana: "思い出します",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: " ",
    meaning: "nhớ lại, hồi tưởng",
  },
  {
    hiragana: "ご家族",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: " ",
    meaning: "gia đình (dùng cho người khác)",
  },
  {
    hiragana: "いらっしゃいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: " ",
    meaning: "thể kính trọng của 「います」",
  },
  {
    hiragana: "高校",
    total: 0,
    right: 0,
    topic: ETopic.UNIT15_N5,
    kanji: " ",
    meaning: "trường trung học phổ thông",
  },
];
export const vocabularyUnit16: IWordOrSentence[] = [
  {
    hiragana: "のります [でんしゃに～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "乗ります [電車に～]",
    meaning: "đi, lên [tàu]",
  },
  {
    hiragana: "おります [でんしゃに～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "降ります [電車に～]",
    meaning: "xuống [tàu]",
  },
  {
    hiragana: "のりかえます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "乗り換えます",
    meaning: "chuyển, đổi (tàu)",
  },
  {
    hiragana: "あびます [シャワーを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "浴びます",
    meaning: "tắm [vòi hoa sen]",
  },
  {
    hiragana: "いれます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "入れます",
    meaning: "cho vào, bỏ vào",
  },
  {
    hiragana: "だします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "出します",
    meaning: "lấy ra, rút (tiền)",
  },
  {
    hiragana: "はいります [だいがくに～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "入ります [大学に～]",
    meaning: "vào, nhập học [đại học]",
  },
  {
    hiragana: "でます [だいがくを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "出ます [大学を～]",
    meaning: "ra, tốt nghiệp [đại học]",
  },
  {
    hiragana: "やめます [かいしゃを～]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "やめます [会社を～]",
    meaning: "bỏ, thôi [việc công ty]",
  },
  {
    hiragana: "おします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "押します",
    meaning: "bấm, ấn (nút)",
  },
  {
    hiragana: "わかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "若い",
    meaning: "trẻ",
  },
  {
    hiragana: "ながい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "長い",
    meaning: "dài",
  },
  {
    hiragana: "みじかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "短い",
    meaning: "ngắn",
  },
  {
    hiragana: "あかるい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "明るい",
    meaning: "sáng",
  },
  {
    hiragana: "くらい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "暗い",
    meaning: "tối",
  },
  {
    hiragana: "せが たかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "背が 高い",
    meaning: "cao (dùng cho người)",
  },
  {
    hiragana: "あたまが いい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "頭が いい",
    meaning: "thông minh",
  },
  {
    hiragana: "からだ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "体",
    meaning: "người, cơ thể",
  },
  {
    hiragana: "あたま",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "頭",
    meaning: "đầu",
  },
  {
    hiragana: "かみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "髪",
    meaning: "tóc",
  },
  {
    hiragana: "なまえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "名前",
    meaning: "tên",
  },
  {
    hiragana: "かお",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "顔",
    meaning: "mặt",
  },
  {
    hiragana: "め",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "目",
    meaning: "mắt",
  },
  {
    hiragana: "みみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "耳",
    meaning: "tai",
  },
  {
    hiragana: "は",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "歯",
    meaning: "răng",
  },
  {
    hiragana: "おなか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "bụng",
  },
  {
    hiragana: "あし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "足",
    meaning: "chân",
  },
  {
    hiragana: "サービス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "dịch vụ",
  },
  {
    hiragana: "ジョギング",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "việc chạy bộ (~をします: chạy bộ)",
  },
  {
    hiragana: "シャワー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "vòi hoa sen",
  },
  {
    hiragana: "みどり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "緑",
    meaning: "màu xanh lá cây",
  },
  {
    hiragana: "[お]てら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "[お]寺",
    meaning: "chùa",
  },
  {
    hiragana: "じんじゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "神社",
    meaning: "đền thờ đạo thần",
  },
  {
    hiragana: "りゅうがくせい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "lưu học sinh, du học sinh",
  },
  {
    hiragana: "一ばん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "一番",
    meaning: "số―",
  },
  {
    hiragana: "どうやって",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "làm thế nào~",
  },
  {
    hiragana: "どの～",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "cái nào~ (dùng với trường hợp từ ba thứ trở lên)",
  },
  {
    hiragana: "[いいえ、]まだまだです。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "[không,] tôi còn kém lắm. (cách nói khiêm nhường khi ai đó khen)",
  },
  {
    hiragana: "まず",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "trước hết, đầu tiên",
  },
  {
    hiragana: "キャッシュカード",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "thẻ ngân hàng, thẻ ATM",
  },
  {
    hiragana: "あんしょうばんごう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "暗証番号",
    meaning: "mã số bí mật (mật khẩu)",
  },
  {
    hiragana: "つぎ に",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "次に",
    meaning: "tiếp theo",
  },
  {
    hiragana: "かくにん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "確認",
    meaning: "sự xác nhận, sự kiểm tra (~します：xác nhận)",
  },
  {
    hiragana: "きんがく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "金額",
    meaning: "số tiền, khoản tiền",
  },
  {
    hiragana: "ボタン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "[",
    meaning: "nút",
  },
  {
    hiragana: "アジア",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: "神社",
    meaning: "châu Á",
  },
  {
    hiragana: "りゅうがくせい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "lưu học sinh, du học sinh",
  },
  {
    hiragana: "一ばん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "số-",
  },
  {
    hiragana: "ベトナム",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "Việt Nam",
  },
  {
    hiragana: "どの～",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "cái nào~ (dùng với trường hợp từ ba thứ trở lên)",
  },
  {
    hiragana: "[いいえ、]まだまだです。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT16_N5,
    kanji: " ",
    meaning: "[không,] tôi còn kém lắm. (cách nói khiêm nhường khi ai đó khen)",
  },
];
export const vocabularyUnit17: IWordOrSentence[] = [
  {
    hiragana: "おぼえます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "覚えます",
    meaning: "nhớ",
  },
  {
    hiragana: "わすれます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "忘れます",
    meaning: "quên",
  },
  {
    hiragana: "なくします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "mất, đánh mất",
  },
  {
    hiragana: "[レポートを] だします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "出します",
    meaning: "nộp(báo cáo)",
  },
  {
    hiragana: "はらいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "払います",
    meaning: "trả tiền",
  },
  {
    hiragana: "かえします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "返します",
    meaning: "trả lại",
  },
  {
    hiragana: "でかけます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "出かけます",
    meaning: "ra ngoài",
  },
  {
    hiragana: "ぬぎます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "脱ぎます",
    meaning: "cởi(quần áo, giầy)",
  },
  {
    hiragana: "もっていきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "持って行きます",
    meaning: "mang đi",
  },
  {
    hiragana: "もってきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "持って来ます",
    meaning: "mang đến",
  },
  {
    hiragana: "しんぱいします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "心配します",
    meaning: "lo lắng",
  },
  {
    hiragana: "ざんぎょうします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "残業します",
    meaning: "làm thêm giờ",
  },
  {
    hiragana: "しゅっちょうします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "出張します",
    meaning: "đi công tác",
  },
  {
    hiragana: "[くすりを] のみます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "「薬を」飲みます",
    meaning: "uống thuốc",
  },
  {
    hiragana: "[おふろに] はいります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "「あふろに」入ります",
    meaning: "tắm bồn",
  },
  {
    hiragana: "たいせつ[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "大切「な」",
    meaning: "quan trọng",
  },
  {
    hiragana: "だいじょうぶ[な]",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "大丈夫「な」",
    meaning: "không sao, không có vấn đề gì",
  },
  {
    hiragana: "あぶない",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "危ない",
    meaning: "nguy hiểm",
  },
  {
    hiragana: "もんだい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "問題",
    meaning: "vấn đề",
  },
  {
    hiragana: "こたえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "答え",
    meaning: "câu trả lời",
  },
  {
    hiragana: "きんえん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "禁煙",
    meaning: "cấm hút thuốc",
  },
  {
    hiragana: "[けんこう] ほけんしょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "健康保険証",
    meaning: "thẻ bảo hiềm(y tế)",
  },
  {
    hiragana: "かぜ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "風",
    meaning: "cảm, cúm",
  },
  {
    hiragana: "ねつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "熱",
    meaning: "sốt",
  },
  {
    hiragana: "びょうき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "病気",
    meaning: "ốm, bệnh",
  },
  {
    hiragana: "くすり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "薬",
    meaning: "thuốc",
  },
  {
    hiragana: "[お]ふろ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "bồn tắm",
  },
  {
    hiragana: "うわぎ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "上着",
    meaning: "áo khoác",
  },
  {
    hiragana: "したぎ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "下着",
    meaning: "quần áo lót",
  },
  {
    hiragana: "せんせい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "先生",
    meaning: "bác sĩ",
  },
  {
    hiragana: "２、３にち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: "２、３日",
    meaning: "vài ngày",
  },
  {
    hiragana: "２、３～",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: 'vài  – ( "–" là hậu tố chỉ các đếm)',
  },
  {
    hiragana: "～までに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "trước ~ (chỉ thời gian)",
  },
  {
    hiragana: "ですから",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "vì thế, vì vậy, do đó",
  },
  {
    hiragana: "どうしましたか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "Có vần đề gì?/Anh/ Chị bị làm sao?",
  },
  {
    hiragana: "[～が] いたいです",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "Tôi bị đau～",
  },
  {
    hiragana: "のど",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "họng",
  },
  {
    hiragana: "おだいじに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT17_N5,
    kanji: " ",
    meaning: "Anh/ chị nhớ giữ gìn sức khỏe.(nói vs người bị ốm)",
  },
];
export const vocabularyUnit18: IWordOrSentence[] = [
  {
    hiragana: "できます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: " ",
    meaning: "có thể",
  },
  {
    hiragana: "あらいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "洗います",
    meaning: "rửa",
  },
  {
    hiragana: "ひきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "弾きます",
    meaning: "chơi (chơi 1 loại nhạc cụ)",
  },
  {
    hiragana: "うたいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "歌います",
    meaning: "hát",
  },
  {
    hiragana: "あつめます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "集めます",
    meaning: "sưu tập",
  },
  {
    hiragana: "すてます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "捨てます",
    meaning: "vứt",
  },
  {
    hiragana: "かえます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: " ",
    meaning: "đổi",
  },
  {
    hiragana: "うんてんします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "運転します",
    meaning: "lái xe",
  },
  {
    hiragana: "よやくします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "予約します",
    meaning: "đặt chỗ trước",
  },
  {
    hiragana: "けんかくします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "見学します",
    meaning: "tham quan mục đích học tập",
  },
  {
    hiragana: "こくさい～",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "国際",
    meaning: "quốc tế",
  },
  {
    hiragana: "げんきん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "現金",
    meaning: "tiền mặt",
  },
  {
    hiragana: "しゅみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "趣味",
    meaning: "sở thích",
  },
  {
    hiragana: "にっき",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "日記",
    meaning: "nhật kí",
  },
  {
    hiragana: "いのり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "祈り",
    meaning: "cầu nguyện",
  },
  {
    hiragana: "かちょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "課長",
    meaning: "tổ trưởng",
  },
  {
    hiragana: "ぶちょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "部長",
    meaning: "trưởng phòng",
  },
  {
    hiragana: "しゃちょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "社長",
    meaning: "giám đốc",
  },
  {
    hiragana: "どうぶつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "動物",
    meaning: "động vật",
  },
  {
    hiragana: "うま",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "馬",
    meaning: "ngựa",
  },
  {
    hiragana: "へえ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: " ",
    meaning: "thế à",
  },
  {
    hiragana: "ピアノ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: " ",
    meaning: "đàn piano",
  },
  {
    hiragana: "―メートル",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: " ",
    meaning: "mét",
  },
  {
    hiragana: "それはおもしろいですね",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: " ",
    meaning: "Hay nhỉ",
  },
  {
    hiragana: "ぼくじょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "牧場",
    meaning: "trang trại",
  },
  {
    hiragana: "ほんとうですか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: "本当ですか",
    meaning: "thật không?",
  },
  {
    hiragana: "ぜひ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT18_N5,
    kanji: " ",
    meaning: "nhất định",
  },
];
export const vocabularyUnit19: IWordOrSentence[] = [
  {
    hiragana: "のぼります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "登ります",
    meaning: "leo (núi)",
  },
  {
    hiragana: "れんしゅうします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "練習します",
    meaning: "luyện tập,thực hành",
  },
  {
    hiragana: "そうじします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "掃除します",
    meaning: "dọn dẹp",
  },
  {
    hiragana: "せんたくします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "洗濯します",
    meaning: "giặt giũ",
  },
  {
    hiragana: "なります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "trở nên ,trở thành",
  },
  {
    hiragana: "とまります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "泊まります",
    meaning: "trọ",
  },
  {
    hiragana: "ねむい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "眠い",
    meaning: "buồn ngủ",
  },
  {
    hiragana: "つよい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "強い",
    meaning: "mạnh",
  },
  {
    hiragana: "よわい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "弱い",
    meaning: "yếu",
  },
  {
    hiragana: "ちょうし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "調子",
    meaning: "tình trạng,trạng thái",
  },
  {
    hiragana: "ちょうしがわるい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "調子が悪い",
    meaning: "tình trạng xấu",
  },
  {
    hiragana: "ちょうしがいい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "調子がいい",
    meaning: "tình trạng tốt",
  },
  {
    hiragana: "おちゃ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "お茶",
    meaning: "trà đạo",
  },
  {
    hiragana: "すもう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "相撲",
    meaning: "vật sumo",
  },
  {
    hiragana: "パチンコ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "trò chơi pachinko",
  },
  {
    hiragana: "ゴルフ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "gôn",
  },
  {
    hiragana: "ひ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "日",
    meaning: "ngày",
  },
  {
    hiragana: "もうすぐ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "sắp sửa",
  },
  {
    hiragana: "だんだん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "dần dần",
  },
  {
    hiragana: "いちど",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "一度",
    meaning: "một lần",
  },
  {
    hiragana: "いちども",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "一度も",
    meaning: "chưa lần nào",
  },
  {
    hiragana: "おかげさまで",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "Cám ơn (khi nhận được sự giúp đỡ của ai đó)",
  },
  {
    hiragana: "かんぱい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "乾杯",
    meaning: "nâng cốc",
  },
  {
    hiragana: "じつは",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "実は",
    meaning: "sự tình là",
  },
  {
    hiragana: "ダイエット",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "ăn kiêng",
  },
  {
    hiragana: "なんかいも",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "何回も",
    meaning: "nhiều lần",
  },
  {
    hiragana: "しかし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: "tuy nhiên",
  },
  {
    hiragana: "むり「な」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "無理「な」",
    meaning: "quá sức",
  },
  {
    hiragana: "からだにいい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: "体にいい",
    meaning: "tốt cho sức khỏe",
  },
  {
    hiragana: "ケーキ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT19_N5,
    kanji: " ",
    meaning: " bánh ga-tô",
  },
];
export const vocabularyUnit20: IWordOrSentence[] = [
  {
    hiragana: "[ビザが～」いります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "要ります",
    meaning: "cần(visa)",
  },
  {
    hiragana: "しらべます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "調べます",
    meaning: "tìm hiểu, điều tra",
  },
  {
    hiragana: "なおします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "直します",
    meaning: "sửa,chữa",
  },
  {
    hiragana: "しゅうりします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "修理します",
    meaning: "sửa chữa,tu sữa",
  },
  {
    hiragana: "でんわします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "電話します",
    meaning: "gọi điện thoại",
  },
  {
    hiragana: "ぼく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "僕",
    meaning: "tớ",
  },
  {
    hiragana: "きみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "君",
    meaning: "cậu,bạn",
  },
  {
    hiragana: "～くん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "～君",
    meaning: "(hậu tố theo sau tên của em trai)",
  },
  {
    hiragana: "うん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "có(cách nói thân mật của “はい”)",
  },
  {
    hiragana: "ううん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "không(cách nói thân mật của “いいえ”)",
  },
  {
    hiragana: "サラリーマン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "người làm việc cho các công ty",
  },
  {
    hiragana: "ことば",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "言葉",
    meaning: "từ, tiếng",
  },
  {
    hiragana: "ぶっか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "物価",
    meaning: "giá cả, mức giá, vật giá",
  },
  {
    hiragana: "きもの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "着物",
    meaning: "kimono (trang phục truyền thông của Nhật Bản)",
  },
  {
    hiragana: "ビザ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "visa",
  },
  {
    hiragana: "はじめ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "始め",
    meaning: "bắt đầu",
  },
  {
    hiragana: "おわり",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "終わり",
    meaning: "kết thúc",
  },
  {
    hiragana: "こっち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "phía này",
  },
  {
    hiragana: "そっち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "phía đó",
  },
  {
    hiragana: "あっち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "phía kia",
  },
  {
    hiragana: "どっち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "ở đâu",
  },
  {
    hiragana: "このあいだ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "この間",
    meaning: "hôm nọ",
  },
  {
    hiragana: "みんなで",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "mọi người",
  },
  {
    hiragana: "～けど",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "nhưng(cách nói thân mật của “が”)",
  },
  {
    hiragana: "くにへかえるの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "国へ帰るの",
    meaning: "Anh/chị có về nước không?",
  },
  {
    hiragana: "どうするの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "Anh/chị tính sao?",
  },
  {
    hiragana: "どうしようかな",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "Tính sao đây/để tôi xem",
  },
  {
    hiragana: "良かったら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: " ",
    meaning: "nếu anh/chị thích thì",
  },
  {
    hiragana: "いろいろ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT20_N5,
    kanji: "色々",
    meaning: "nhiều thứ,, đa dạng",
  },
];
export const vocabularyUnit21: IWordOrSentence[] = [
  {
    hiragana: "おもいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "思います",
    meaning: "nghĩ",
  },
  {
    hiragana: "いいます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "言います",
    meaning: "nói",
  },
  {
    hiragana: "たります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "足ります",
    meaning: "đủ",
  },
  {
    hiragana: "かちます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "勝ちます",
    meaning: "thắng",
  },
  {
    hiragana: "まけます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "負けます",
    meaning: "thua",
  },
  {
    hiragana: "あります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "được tổ chức",
  },
  {
    hiragana: "やくにたちます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "役に立ちます",
    meaning: "giúp ích",
  },
  {
    hiragana: "むだ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "vô ích",
  },
  {
    hiragana: "ふべん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "不便",
    meaning: "bất tiện",
  },
  {
    hiragana: "おなじ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "同じ",
    meaning: "giống",
  },
  {
    hiragana: "すごい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "giỏi",
  },
  {
    hiragana: "しゅしょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "首相",
    meaning: "thủ tướng",
  },
  {
    hiragana: "だいとうりょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "大統領",
    meaning: "tổng thống",
  },
  {
    hiragana: "せいじ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "政治",
    meaning: "chính trị",
  },
  {
    hiragana: "ニュース",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "tin tức",
  },
  {
    hiragana: "スピーチ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "bài phát biểu",
  },
  {
    hiragana: "しあい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "試合",
    meaning: "trận đấu",
  },
  {
    hiragana: "アルバイト",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "việc làm thêm",
  },
  {
    hiragana: "いけん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "意見",
    meaning: "ý kiến",
  },
  {
    hiragana: "はなし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "話",
    meaning: "câu chuyện",
  },
  {
    hiragana: "ユーモア",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "hài hước",
  },
  {
    hiragana: "デザイン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "thiết kế",
  },
  {
    hiragana: "こうつう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "交通",
    meaning: "giao thông",
  },
  {
    hiragana: "ラッシュ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "giờ cao điểm",
  },
  {
    hiragana: "さいきん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "最近",
    meaning: "gần đây",
  },
  {
    hiragana: "たぶん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "chắc, có thể",
  },
  {
    hiragana: "きっと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "chắc chắn , nhất định",
  },
  {
    hiragana: "ほんとうに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "本当に",
    meaning: "thật sự",
  },
  {
    hiragana: "そんなに",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "(không ) ~ lắm",
  },
  {
    hiragana: "～について",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "theo ~ về",
  },
  {
    hiragana: "しかたがありません",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "không còn cách nào khác",
  },
  {
    hiragana: "しばらくですね",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "Lâu rồi không gặp",
  },
  {
    hiragana: "～でものみませんか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "～でも飲みませんか",
    meaning: "Anh/chị uống (cà phê, rượu hay cái gì đó) nhé",
  },
  {
    hiragana: "みないと。。。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: "見ないと。。。",
    meaning: "phải xem…",
  },
  {
    hiragana: "もちろん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "tất nhiên",
  },
  {
    hiragana: "カンガルー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT21_N5,
    kanji: " ",
    meaning: "Kanguru",
  },
];
export const vocabularyUnit22: IWordOrSentence[] = [
  {
    hiragana: "きます「シャツを～」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "着ます",
    meaning: "mặc (áo sơ mi,..)",
  },
  {
    hiragana: "はきます「くつを～」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "mang (giày,…)",
  },
  {
    hiragana: "かぶります「ぼうしを～」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "đội (nón,…)",
  },
  {
    hiragana: "かけます「めがねを～」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "đeo (kính,…)",
  },
  {
    hiragana: "うまれます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "生まれます",
    meaning: "sinh ra",
  },
  {
    hiragana: "コートー",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "áo khoác",
  },
  {
    hiragana: "スーツ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "com-lê",
  },
  {
    hiragana: "セーター",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "áo len",
  },
  {
    hiragana: "ぼうし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "帽子",
    meaning: "nón, mũ",
  },
  {
    hiragana: "めがね",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "眼鏡",
    meaning: "kính",
  },
  {
    hiragana: "よく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "thường",
  },
  {
    hiragana: "おめでとうございます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "Chúc mừng",
  },
  {
    hiragana: "こちら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "cái này (cách nói lịch sử của “これ”)",
  },
  {
    hiragana: "やちん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "家賃",
    meaning: "tiền nhà",
  },
  {
    hiragana: "うーん。",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "để tôi xem/ừ/thế nào nhỉ",
  },
  {
    hiragana: "ダイニングキッチン",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "bếp kèm phòng ăn",
  },
  {
    hiragana: "わしつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "phòng kiểu Nhật",
  },
  {
    hiragana: "おしいれ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "押し入れ",
    meaning: "chỗ để chăn gối trong phòng kiểu Nhật",
  },
  {
    hiragana: "ふとん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "布団",
    meaning: "chăn,đệm",
  },
  {
    hiragana: "アパート",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "nhà chung cư",
  },
  {
    hiragana: "パリ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: " ",
    meaning: "Pa-ri",
  },
  {
    hiragana: "ばんりのちょうじょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "万里の長城",
    meaning: "Vạn lý trường thành",
  },
  {
    hiragana: "よかかいはつセンター",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "余暇開発センター",
    meaning: "Trung tâm phát triển hoạt động giải trí cho người dân",
  },
  {
    hiragana: "レジャーはくしょ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT22_N5,
    kanji: "レジャー白書",
    meaning: "sách trắng về sử dụng thời gian rảnh rỗi",
  },
];
export const vocabularyUnit23: IWordOrSentence[] = [
  {
    hiragana: "ききます「先生に」",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "聞きます",
    meaning: "hỏi (giáo viên)",
  },
  {
    hiragana: "みち",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "道",
    meaning: "đường",
  },
  {
    hiragana: "こうさてん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "操作店",
    meaning: "ngã tư",
  },
  {
    hiragana: "しんごう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "信号",
    meaning: "đèn tín hiệu",
  },
  {
    hiragana: "ひっこしします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "引越しします",
    meaning: "chuyển nhà",
  },
  {
    hiragana: "まわします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "回します",
    meaning: "vặn (nút)",
  },
  {
    hiragana: "ひきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "引きます",
    meaning: "kéo",
  },
  {
    hiragana: "かえます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "変えます",
    meaning: "đổi",
  },
  {
    hiragana: "さわります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "触ります",
    meaning: "sờ ,chạm vào",
  },
  {
    hiragana: "でます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "出ます",
    meaning: "ra,đi ra",
  },
  {
    hiragana: "うごきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "動きます。",
    meaning: "chuyển động ,chạy",
  },
  {
    hiragana: "あるきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "歩きます",
    meaning: "đi bộ (trên đường)",
  },
  {
    hiragana: "わたります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "渡ります",
    meaning: "qua ,đi qua (cầu)",
  },
  {
    hiragana: "きをつけます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "気を付けます",
    meaning: "chú ý,cẩn thận",
  },
  {
    hiragana: "でんきや",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "電気屋",
    meaning: "cửa hàng đồ điện",
  },
  {
    hiragana: "こしょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "故障",
    meaning: "hỏng",
  },
  {
    hiragana: "―や",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "ー屋",
    meaning: "cửa hàng–",
  },
  {
    hiragana: "サイズ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: " ",
    meaning: "cỡ,kích thước",
  },
  {
    hiragana: "おと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "音",
    meaning: "âm thanh",
  },
  {
    hiragana: "きかい",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "機械",
    meaning: "máy móc",
  },
  {
    hiragana: "つまみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: " ",
    meaning: "núm vặn",
  },
  {
    hiragana: "かど",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "角",
    meaning: "góc",
  },
  {
    hiragana: "はし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "橋",
    meaning: "cầu",
  },
  {
    hiragana: "ちゅうしゃじょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "駐車場",
    meaning: "bãi đỗ xe",
  },
  {
    hiragana: "おしょうがつ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "お正月",
    meaning: "tết dương lịch",
  },
  {
    hiragana: "ごちそうさまでした",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: " ",
    meaning: "xin cảm ơn anh chị đã đãi tôi bữa cơm",
  },
  {
    hiragana: "ーめ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "ー目",
    meaning: "thứー, số ー (biểu thị thứ tự)",
  },
  {
    hiragana: "たてもの",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "建物",
    meaning: "tòa nhà",
  },
  {
    hiragana: "がいこくじんとうろくしょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "外国人登録証",
    meaning: "thẻ đăng kí người nước ngoài / thẻ đăng kí ngoại kiều",
  },
  {
    hiragana: "います",
    total: 0,
    right: 0,
    topic: ETopic.UNIT23_N5,
    kanji: "借ります",
    meaning: "行きます",
  },
];
export const vocabularyUnit24: IWordOrSentence[] = [
  {
    hiragana: "くれます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: " ",
    meaning: "cho ,tặng(tôi)",
  },
  {
    hiragana: "つれていきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "連れて行きます",
    meaning: "dẫn đi",
  },
  {
    hiragana: "つれてきます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "連れて来ます",
    meaning: "dẫn đến",
  },
  {
    hiragana: "おくります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "送ります",
    meaning: "đưa đi ,đưa đến,tiễn",
  },
  {
    hiragana: "しょうかいします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "紹介します",
    meaning: "giới thiệu",
  },
  {
    hiragana: "あんないします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "案内します",
    meaning: "hướng dẫn,chỉ đường",
  },
  {
    hiragana: "せつめいします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "説明します",
    meaning: "giải thích ,trình bày",
  },
  {
    hiragana: "いれます(コーヒーを）",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: " ",
    meaning: "pha(cà phê)",
  },
  {
    hiragana: "おじいさん / おじいちゃん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: " ",
    meaning: "ông nội,ông ngoại,ông",
  },
  {
    hiragana: "おばあさん /　おばあちゃん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: " ",
    meaning: "bà nội,bà ngoại,bà",
  },
  {
    hiragana: "じゅんび",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "準備",
    meaning: "chuẩn bị",
  },
  {
    hiragana: "いみ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "意味",
    meaning: "ý nghĩa",
  },
  {
    hiragana: "おかし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "お菓子",
    meaning: "bánh kẹo",
  },
  {
    hiragana: "ぜんぶ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT24_N5,
    kanji: "全部",
    meaning: "toàn bộ,tất cả",
  },
];
export const vocabularyUnit25: IWordOrSentence[] = [
  {
    hiragana: "かんがえます",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "考えます",
    meaning: "nghĩ,suy nghĩ",
  },
  {
    hiragana: "つきます（駅に～）",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "着きます",
    meaning: "đến(ga)",
  },
  {
    hiragana: "りゅうがくします",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "留学します",
    meaning: "du học",
  },
  {
    hiragana: "とります（年を～）",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "取ります",
    meaning: "thêm (tuổi)",
  },
  {
    hiragana: "いなか",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "田舎",
    meaning: "quê,nông thôn",
  },
  {
    hiragana: "たいしかん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "大使館",
    meaning: "đại sứ quán",
  },
  {
    hiragana: "グループ",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: " ",
    meaning: "nhóm, bạn bè",
  },
  {
    hiragana: "チャンス",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: " ",
    meaning: "cơ hội",
  },
  {
    hiragana: "おく",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "億",
    meaning: "một trăm triệu",
  },
  {
    hiragana: "もし",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: " ",
    meaning: "nếu",
  },
  {
    hiragana: "いくら",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: " ",
    meaning: "cho dù,thế nào",
  },
  {
    hiragana: "てんきん",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "転勤",
    meaning: "chuyển công tác",
  },
  {
    hiragana: "こと",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: " ",
    meaning: "việc",
  },
  {
    hiragana: "いっぱいのみましょう",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "一杯飲みます",
    meaning: "chúng ta cùng uống nhé",
  },
  {
    hiragana: "どうぞおげんきで",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "どうぞ元気で",
    meaning:
      "chúc anh chị mạnh khỏe (câu nói trước khi chia tay với ai đó mà có lẽ lâu nữa mới gặp lại)",
  },
  {
    hiragana: "（いろいろ）おせわになりました",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "（いろいろ）お世話になりました",
    meaning: "Anh/chị đã giúp tôi nhiều",
  },
  {
    hiragana: "がんばります",
    total: 0,
    right: 0,
    topic: ETopic.UNIT25_N5,
    kanji: "頑張ります",
    meaning: "cố gắng",
  },
];
export const vocabularyAll: IWordOrSentence[] = [
  ...vocabularyUnit1,
  ...vocabularyUnit2,
  ...vocabularyUnit3,
  ...vocabularyUnit4,
  ...vocabularyUnit5,
  ...vocabularyUnit6,
  ...vocabularyUnit7,
  ...vocabularyUnit8,
  ...vocabularyUnit9,
  ...vocabularyUnit10,
  ...vocabularyUnit11,
  ...vocabularyUnit12,
  ...vocabularyUnit13,
  ...vocabularyUnit14,
  ...vocabularyUnit15,
  ...vocabularyUnit16,
  ...vocabularyUnit17,
  ...vocabularyUnit18,
  ...vocabularyUnit19,
  ...vocabularyUnit20,
  ...vocabularyUnit21,
  ...vocabularyUnit22,
  ...vocabularyUnit23,
  ...vocabularyUnit24,
  ...vocabularyUnit25,
];

export const formatToCSV = (data: IWordOrSentence[]) => {
  let csv =
    "No,Romaji,Hiragana,Kanji,Các từ đi kèm (collocation),Meaning,Ví dụ,Đồng nghĩa,Trái nghĩa,Level của từ,Chủ đề,Nguồn\n";
  data.forEach((item, index) => {
    // item.topic = unit1_n5; => item.topic = "Unit1, N5" ans capilize first letter of each word.
    const topics = item.topic.split("_");
    let topic = "";
    topics.forEach((t) => {
      topic += t.charAt(0).toUpperCase() + t.slice(1).toLowerCase();
      if (topics.indexOf(t) !== topics.length - 1) {
        topic += ", ";
      }
    });
    csv += `${index + 1},,${item.hiragana},${item.kanji},,"${
      item.meaning
    }",,,,"${topic}",,\n`;
  });
  return csv;
}

// var vocabulary = "";
// var result = [];

// var tr = document.querySelectorAll("table tr");
// for (var i = 1; i < tr.length; i++) {
//   var td = tr[i].querySelectorAll("td");
//   var tmp = {};
//   for (var j = 1; j < td.length; j++) {
//     if (j === 0) {
//       tmp = {
//         ...tmp,
//         no: td[j].innerText,
//       };
//     }
//     if (j === 1) {
//       tmp = {
//         ...tmp,
//         hiragana: td[j].innerText,
//       };
//     }
//     if (j === 2) {
//       tmp = {
//         ...tmp,
//         kanji: td[j].innerText,
//       };
//     }
//     if (j === 3) {
//       tmp = {
//         ...tmp,
//         meaning: td[j].innerText,
//       };
//     }
//     tmp = {
//       ...tmp,
//       total: 0,
//       right: 0,
//       topic: "UNIT1",
//     };
//   }
//   result.push(tmp);
//   tmp = {};
// }
// console.log(result);