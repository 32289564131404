import * as React from 'react';

export interface IWorkIconProps {
  className?: string;
}

export function WorkIcon ({
  className
}: IWorkIconProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} fill-white`}
    >
      <path
        d="M8.18323 2.396C8.18323 1.43 8.96723 0.645996 9.93323 0.645996H15.4332C16.3992 0.645996 17.1832 1.43 17.1832 2.396V4.646H21.9332C22.8992 4.646 23.6832 5.43 23.6832 6.396V20.896C23.6832 21.3601 23.4989 21.8052 23.1707 22.1334C22.8425 22.4616 22.3974 22.646 21.9332 22.646H3.43323C2.9691 22.646 2.52398 22.4616 2.19579 22.1334C1.8676 21.8052 1.68323 21.3601 1.68323 20.896V6.396C1.68323 5.43 2.46723 4.646 3.43323 4.646H8.18323V2.396ZM3.18323 12.636V20.896C3.18323 21.034 3.29523 21.146 3.43323 21.146H21.9332C21.9995 21.146 22.0631 21.1197 22.11 21.0728C22.1569 21.0259 22.1832 20.9623 22.1832 20.896V12.636C21.4159 13.2891 20.4409 13.6472 19.4332 13.646H5.93323C4.9256 13.6472 3.95055 13.2891 3.18323 12.636ZM22.1832 9.396V6.396C22.1832 6.32969 22.1569 6.2661 22.11 6.21922C22.0631 6.17234 21.9995 6.146 21.9332 6.146H3.43323C3.36692 6.146 3.30333 6.17234 3.25645 6.21922C3.20957 6.2661 3.18323 6.32969 3.18323 6.396V9.396C3.18323 10.1253 3.47296 10.8248 3.98868 11.3405C4.50441 11.8563 5.20388 12.146 5.93323 12.146H19.4332C20.1626 12.146 20.862 11.8563 21.3778 11.3405C21.8935 10.8248 22.1832 10.1253 22.1832 9.396ZM15.6832 2.396C15.6832 2.32969 15.6569 2.2661 15.61 2.21922C15.5631 2.17234 15.4995 2.146 15.4332 2.146H9.93323C9.86692 2.146 9.80334 2.17234 9.75645 2.21922C9.70957 2.2661 9.68323 2.32969 9.68323 2.396V4.646H15.6832V2.396Z"
      />
    </svg>
  );
}
